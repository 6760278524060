import React from "react";
import classNames from "classnames";

import { Container, Title } from "@components/ui";
import Footer from "./Footer/Footer";
import { Header } from "./Header/Header";
import Head from "next/head";
import { WEBPAGE_TITLE } from "@utils/Constants";

function Page({
    children,
    title,
    showsSearchBar,
    headTitle,
    headDescription,
    headImageUrl,
    headUrl,
    fluid,
    bodyStyle,
    bodyClassName,
    className,
    showsFooter = true,
}) {
    var headerTitle = "";
    if (headTitle) {
        headerTitle = headTitle + " - " + WEBPAGE_TITLE;
    } else if (title) {
        headerTitle = title + " - " + WEBPAGE_TITLE;
    } else {
        headerTitle = WEBPAGE_TITLE;
    }
    return (
        <>
            <Head>
                <title key="site_title">{headerTitle}</title>
                {headImageUrl && (
                  <meta
                    property="og:image"
                    content={headImageUrl}
                    key="og:image"
                  />
                )}
                <meta
                  property="og:site_name"
                  content="Laufið"
                />
                <meta
                  property="og:image:width"
                  content="1018"
                  key="og:image:width"
                />
                <meta
                  property="og:image:height"
                  content="360"
                  key="og:image:height"
                />

                {title && (
                  <meta
                    property="og:title"
                    content={headerTitle}
                    key="og:title"
                  />
                )}
                {headUrl && (
                  <meta property="og:url" content={headUrl} key="og:url"/>
                )}
                {headDescription && (
                  <meta
                    property="og:description"
                    content={headDescription}
                    key="og:description"
                  />
                )}
            </Head>
            <div className={classNames("page", className)}>
                <Header showsSearchBar={showsSearchBar}/>

                <div
                  id="page-content"
                    className="page-content d-flex flex-column"
                >
                    <PageTitle title={title} />
                    <Container
                        fluid={fluid}
                        className={classNames("page-body", bodyClassName)}
                        style={{
                            flexGrow: 1,
                            margin: 0,
                            padding: 0,

                            ...bodyStyle,
                        }}
                    >
                        {children}
                    </Container>
                </div>
                <Footer showsNavigation={showsFooter} />
            </div>
        </>
    );
}

function PageTitle({ title }) {
    return (
        <Container>
            {title ? (
                <Title
                    size={1}
                    className="fw-extra text-blue"
                    style={{ marginTop: 20 }}
                >
                    {title}
                </Title>
            ) : (
                <></>
            )}
        </Container>
    );
}

export default Page;
